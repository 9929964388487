import { MouseEvent } from 'react';
import { context } from '@shared-services/context-service';
import { CustomField } from 'client/widget-components/ecom/types';
import { ProductEntity } from 'client/widget-components/ecom/stores/types';
import { isObjectEmpty } from 'client/utils';
import {
    DEFAULT_VARIATION_ID,
    ProductState,
} from 'client/widget-components/ecom/stores/ProductState';
import { PRODUCT_ONE_TIME_PAYMENT_PLAN_ID } from 'client/widget-components/ecom/consts';
import { logService } from '@duda-co/host-services';
import { featureFlags } from '@shared-services/feature-flags';

const newAddToPRoductEnabled = featureFlags.getBoolean(
    `runtime.ssr.ssrAddToCart.snipcart.new.api.addProduct.enabled`,
    false
);

export type ComponentProps = {
    isDisabled: boolean;
    isSubscribe: boolean;
};

export const useAddToCart = ({
    product,
    productState,
}: {
    product: ProductEntity;
    productState: ProductState;
}) => {
    if (isObjectEmpty(product)) {
        return { elementAttributes: {}, componentProps: {} };
    }
    const {
        itemId,
        productId,
        name,
        price,
        image,
        selected_options_values,
        internal_quantity,
        shippable,
    } = product;

    const quantityEnabled = !!internal_quantity || internal_quantity === 0;
    const shouldDisable = quantityEnabled && internal_quantity! <= 0;

    const getIsSubscribe = () =>
        !!productState.selectedPaymentPlan &&
        productState.selectedPaymentPlan.id != PRODUCT_ONE_TIME_PAYMENT_PLAN_ID;

    const onClick = async (e: MouseEvent<HTMLElement>) => {
        if (
            context.isEditor ||
            !window.Snipcart ||
            !window.dmAPI ||
            shouldDisable
        ) {
            return;
        }
        if (getIsSubscribe()) {
            await window.Snipcart.api.products.buy({
                product_id: productId,
                variation_id:
                    productState.selectedVariation?.identifier ||
                    DEFAULT_VARIATION_ID,
                plan_id: productState.selectedPaymentPlan?.id || '',
            });
        } else {
            e.stopPropagation();
            try {
                if (newAddToPRoductEnabled) {
                    const addItemToCart = window._ecomSDK?.addProduct;
                    addItemToCart?.({
                        product_id: productId,
                        variation_id:
                            productState.selectedVariation?.identifier ||
                            DEFAULT_VARIATION_ID,
                        quantity: 1,
                    });
                } else {
                    const addItemToCart = window.Snipcart.api.cart.items.add;
                    addItemToCart({
                        id: itemId,
                        price,
                        name,
                        image,
                        quantity: 1,
                        url: window.location.href, // Ignored by Snipcart but needed for validation
                        shippable,
                        stackable: true,
                        taxable: true,
                        customFields: getSnipcartCustomFields(),
                        maxQuantity: quantityEnabled
                            ? internal_quantity
                            : undefined,
                    });
                }
            } catch (err) {
                const objectToLog = {
                    message: 'Error in window._ecomSDK?.addProduct',
                    tags: [
                        '__new-runtime__',
                        'native-ecom',
                        'add-items-to-cart',
                    ],
                    errorMessage: err,
                };
                logService.error(objectToLog);
            }
        }
    };

    const getSnipcartCustomFields = () => {
        if (!selected_options_values) {
            return;
        }
        const selectedOptions: { [key: string]: string } = JSON.parse(
            selected_options_values
        );
        const customFields: CustomField[] = Object.entries(selectedOptions).map(
            ([key, option]) => ({
                value: option,
                name: key,
                type: 'readonly',
            })
        );

        return customFields;
    };

    const elementAttributes = {
        'data-item-id': itemId,
        'data-item-price': price,
        'data-auto': 'addToCart',
        className: 'snipcart-add-item',
        onClick,
    };

    const componentProps: ComponentProps = {
        isDisabled: shouldDisable,
        isSubscribe: getIsSubscribe(),
    };

    return { elementAttributes, componentProps };
};
