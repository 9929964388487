import React from 'react';
import { RuntimeWidget } from 'client/types';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import styled from 'styled-components';

const RuntimeCalBooking: RuntimeWidget = () => {
    return (
        <CalBookingWrapper data-auto='ssr-cal-booking-widget'>
            CalBookingWrapper
        </CalBookingWrapper>
    );
};

const CalBookingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default withErrorBoundary({
    Comp: RuntimeCalBooking,
    logProps: true,
    componentName: 'CalBooking',
    additionalInfoToLog: {
        tag: 'native-ecom',
    },
});
