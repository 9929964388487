import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import propsStore, { WidgetStore } from '../stores/PropsStore';
import { toJS, configure } from 'mobx';
import { EditorStateContext } from './editorState';
import { postToEditor } from 'client/editorMsApi/postToEditor';

configure({ isolateGlobalState: true });

interface ObserverWidgetProps {
    Component: React.FC<any>;
    widgetId: string;
}

function ObserverWidget({ Component, widgetId }: ObserverWidgetProps) {
    const widgetStore: WidgetStore = propsStore.getWidgetStore(widgetId);

    useEffect(() => {
        postToEditor({
            type: 'widget-rendered-following-props-change',
            widgetId,
        });
    }, [widgetStore.props]);

    const props = toJS(widgetStore.props);
    return (
        <EditorStateContext>
            <Component widgetId={widgetId} {...props} />
        </EditorStateContext>
    );
}

export default observer(ObserverWidget);
