import { Logger } from 'shared-services/types/log-service';
import { log } from '@duda-co/host-services';

function extendDataToLog(data: string | object) {
    return typeof data === 'string'
        ? data
        : { url: window.location.href, ...data };
}

export const logger: Logger = new Proxy(log.log, {
    get: (target, prop: keyof Logger) => (data: string | object) => {
        target[prop](extendDataToLog(data));
    },
});
