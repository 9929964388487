import { logger } from '@shared-services/log-service';
import React, { ReactNode } from 'react';
import ErrorFallback from './ErrorFallback';

interface Props {
    additionalInfoToLog: Record<string, string | number | boolean>;
    children: ReactNode;
}
interface State {
    hasError: boolean;
}

class _ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        const { additionalInfoToLog } = this.props;
        const objectToLog = {
            message: 'Rendering error caught in error boundary. ',
            tags: ['__new-runtime__', `ErrorBoundary`],
            errorMessage: error?.message,
            additionalInfoToLog,
        };
        logger.warn(objectToLog);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback />;
        }

        return this.props.children;
    }
}

export interface WithErrorBoundaryProps<T extends object> {
    Comp: React.FC<T>;
    componentName: string;
    additionalInfoToLog?: Record<string, string | number | boolean>;
    logProps?: boolean;
}

function withErrorBoundary<T extends object>({
    Comp,
    additionalInfoToLog: _additionalInfoToLog,
    logProps,
    componentName,
}: WithErrorBoundaryProps<T>): React.FC<T> {
    return (props: any) => {
        const objectToLog: Record<string, any> = {
            ..._additionalInfoToLog,
        };
        if (componentName) {
            objectToLog.componentName = componentName;
        }
        if (logProps) {
            objectToLog.childComponentProps = props;
        }
        return (
            <_ErrorBoundary additionalInfoToLog={objectToLog}>
                <Comp {...props} />
            </_ErrorBoundary>
        );
    };
}

export default withErrorBoundary;
