import React, { FC, useEffect, useMemo, useState } from 'react';
import { AccordionStyles, RuntimeAccordionProps } from './accordionCommonTypes';
import { Div, Ul } from 'client/widget-components/basicComponents';
import { Placeholder } from 'client/widget-components/components/Placeholder';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { accordionlayoutTypeToDesignData } from './accordionLayouts';
import AccordionItem from './accordion-item/AccordionItem';

const RuntimeAccordion: FC<RuntimeAccordionProps> = ({
    _styles,
    layout = 'LAYOUT_1',
    itemsData,
    firstExpanded,
    closeOthers,
    openedIndex,
    addSchemaMarkup,
    titleTag = 'h3',
}) => {
    const [openItems, setOpenItems] = useState(
        openedIndex !== undefined ? [openedIndex] : firstExpanded ? [0] : []
    );

    useEffect(() => {
        setOpenItems(
            openedIndex !== undefined ? [openedIndex] : firstExpanded ? [0] : []
        );
    }, [openedIndex, firstExpanded]);

    const onItemToggle = (item: number) => {
        let newItems = [...openItems];
        const index = newItems.indexOf(item);
        if (index === -1) {
            newItems.push(item);
        } else {
            newItems.splice(index, 1);
        }
        if (closeOthers) {
            newItems = newItems.filter((i) => i === item);
        }
        setOpenItems(newItems);
    };

    const layoutDesignData = accordionlayoutTypeToDesignData[layout];
    const { styles: layoutStyles } = layoutDesignData;
    const styles: AccordionStyles = {
        container: [layoutStyles?.container, _styles?.container],
        itemContainer: [layoutStyles?.itemContainer, _styles?.itemContainer],
        itemTitleWrapper: [
            layoutStyles?.itemTitleWrapper,
            _styles?.itemTitleWrapper,
        ],
        itemTitle: [layoutStyles?.itemTitle, _styles?.itemTitle],
        itemTitleIcon: [layoutStyles?.itemTitleIcon, _styles?.itemTitleIcon],
        itemArrowWrapper: [
            layoutStyles?.itemArrowWrapper,
            _styles?.itemArrowWrapper,
        ],
        itemArrow: [layoutStyles?.itemArrow, _styles?.itemArrow],
        itemArrowSvg: [layoutStyles?.itemArrowSvg, _styles?.itemArrowSvg],
        itemDesc: [layoutStyles?.itemDesc, _styles?.itemDesc],
        desc: [layoutStyles?.desc, _styles?.desc],
        button: {
            root: [layoutStyles?.button?.root, _styles?.button?.root],
            text: [layoutStyles?.button?.text, _styles?.button?.text],
            rootHover: [
                layoutStyles?.button?.rootHover,
                _styles?.button?.rootHover,
            ],
            rootHoverText: [
                layoutStyles?.button?.rootHoverText,
                _styles?.button?.rootHoverText,
            ],
            buttonTypeCSSClass: _styles?.button?.buttonTypeCSSClass,
        },
        mediaContainer: [layoutStyles?.mediaContainer, _styles?.mediaContainer],
        media: [layoutStyles?.media, _styles?.media],
    };

    const schemaMarkup = useMemo(() => {
        const schemaObj = {
            '@context': 'http://schema.org/',
            '@type': 'FAQPage',
            mainEntity: itemsData.map((item) => {
                return {
                    '@type': 'Question',
                    name: item.title,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: item.desc,
                    },
                };
            }),
        };
        return JSON.stringify(schemaObj);
    }, [itemsData]);

    return (
        <Div data-auto='runtime-accordion-widget'>
            {addSchemaMarkup && itemsData.length > 0 ? (
                <script
                    data-auto='schema'
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{ __html: schemaMarkup }}
                ></script>
            ) : null}
            <Ul
                styles={[accordionWrapperDefaultStyles, styles?.container]}
                data-grab='accordion-container'
            >
                {itemsData.length === 0 ? (
                    <Placeholder />
                ) : (
                    itemsData.map((data, index) => {
                        return (
                            <AccordionItem
                                {...data}
                                key={`item-${index}`}
                                styles={styles}
                                arrowDirection={layoutDesignData.arrowDirection}
                                arrowType={layoutDesignData.arrowType}
                                hasTitleIcons={layoutDesignData.hasTitleIcons}
                                onItemToggle={onItemToggle}
                                index={index}
                                isOpen={openItems.includes(index)}
                                titleTag={titleTag}
                                disableTransition={openedIndex !== undefined}
                            />
                        );
                    })
                )}
            </Ul>
        </Div>
    );
};

const accordionWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        listStyleType: 'none',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
    },
};

export default RuntimeAccordion;
