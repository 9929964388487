import { makeAutoObservable } from 'mobx';
import { ProductType } from './types';
import { ProductState } from './ProductState';
import { isObjectEmpty } from 'client/utils';

class ProductsStore {
    // storeProducts: Map<productId: string, variations: ProductState>
    storeProducts: Map<string, ProductState> = new Map();
    currentProduct?: ProductState;

    constructor() {
        makeAutoObservable(this);
    }

    getProductById(productId: string) {
        return this.storeProducts.get(productId);
    }
    addProduct(product: ProductType) {
        if (isObjectEmpty(product)) {
            return;
        }
        const productId = product.identifier;
        if (productId && !this.storeProducts.has(productId)) {
            this.currentProduct = new ProductState(product);
            this.storeProducts.set(productId, this.currentProduct);
        }
        return this.storeProducts.get(productId);
    }
}

declare global {
    var productsStore: ProductsStore;
}

globalThis.productsStore = globalThis.productsStore || new ProductsStore();
export default globalThis.productsStore;
