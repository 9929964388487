import RuntimeCart from 'client/widgets/runtime-cart';
import RuntimeFilterSort from 'client/widgets/runtime-filter-sort';
import RuntimeOptionsVariations from 'client/widgets/runtime-options-variations';
import RuntimeAddToCart from 'client/widgets/runtime-add-to-cart';
import propsStore from 'client/infra/stores/PropsStore';
import runtimeBreadcrumbs from 'client/widgets/runtime-breadcrumbs';
import RuntimeSlider from 'client/widgets/runtime-slider';
import { RuntimeWidget, WidgetType } from 'client/types';
import { GeneralWidgetProps, WidgetSchema } from 'types';
import RuntimeCollectionSearchWidget from 'client/widgets/runtime-collection-search/RuntimeCollectionSearch';
import RuntimeProductPriceWidget from 'client/widgets/runtime-product-price/RuntimeProductPrice';
import RuntimeProductPriceOptionsWidget from 'client/widgets/runtime-product-price-options/RuntimeProductPriceOptions';
import RuntimeAccordion from 'client/widgets/runtime-accordion/RuntimeAccordion';
import RuntimeCalBooking from 'client/widgets/runtime-cal-booking/RuntimeCalBooking';
export interface GenerateComponentArgs extends WidgetSchema {
    observer: boolean;
}

const legacyStoreWidgets = [WidgetType.Cart, WidgetType.Breadcrumbs];

/** hot expects a unique component. Hot is doing some kind of compilation magic. Top Component must be written in a different file (can't come from same HOC). If HOC is used, wrap in a dummy component */
const typeToWidget: Record<WidgetType, RuntimeWidget> = {
    [WidgetType.Slider]: RuntimeSlider,
    [WidgetType.Cart]: RuntimeCart,
    [WidgetType.Breadcrumbs]: runtimeBreadcrumbs,
    [WidgetType.AddToCart]: RuntimeAddToCart,
    [WidgetType.OptionsVariations]: RuntimeOptionsVariations,
    [WidgetType.RuntimeFilterSort]: RuntimeFilterSort,
    [WidgetType.CollectionSearch]: RuntimeCollectionSearchWidget,
    [WidgetType.ProductPrice]: RuntimeProductPriceWidget,
    [WidgetType.ProductPriceOptions]: RuntimeProductPriceOptionsWidget,
    [WidgetType.Accordion]: RuntimeAccordion,
    [WidgetType.CalBooking]: RuntimeCalBooking,
};

export const getComponentByType = (type: WidgetType): RuntimeWidget => {
    return typeToWidget[type];
};

export const populateWidgetStore = (
    widgetID: string,
    props: GeneralWidgetProps,
    type: WidgetType
) => {
    const widgetStore = propsStore.getWidgetStore(widgetID);
    widgetStore.legacyWidget =
        legacyStoreWidgets.includes(type) || !!widgetStore.model.styles?.rules;
    widgetStore.mergeModel(props);
    widgetStore.setInitialProps(props, widgetID);
};

function endsWith(str: string, suffix: string) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function appendMarkupInsideDiv(
    divMarkup: string,
    newMarkup: string
): string {
    if (endsWith(divMarkup, '</div>')) {
        return divMarkup.replace(/<\/div>$/, newMarkup + '</div>');
    }
    return divMarkup;
}

export function appendMarkupBeforeInsideDiv(
    divMarkup: string,
    newMarkup: string
): string {
    if (endsWith(divMarkup, '</div>')) {
        const match = divMarkup.match(/^<div(.*?)>/) as Array<string>;
        return divMarkup.replace(/^<div(.*?)>/, match[0] + newMarkup);
    }
    return divMarkup;
}

export function shouldHydrateWidgetOnRuntime(widget: WidgetSchema): boolean {
    const Component = getComponentByType(widget.type);
    return !Component?.skipHydration;
}

export function getWrapperId(id: string) {
    return `ssrWrap-${id}`;
}
