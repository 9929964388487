import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { Div } from 'client/widget-components/basicComponents';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import SlideContent from '../SlideContent';
import { SlideData } from '../sliderCommonTypes';
import {
    slideShowCommonDefaultStyles,
    SlideShowProps,
} from './SlideShowCommon';
import usePrevious from 'client/widget-components/customHooks/usePrevious';
import useWrapperStyle from 'client/widget-components/customHooks/useWrapperStyle';
import useWrapDirection from './useWrapDirection';
import calculatePositions from './calculatePositions';
import { getSlideShowLayoutPaginationStylesOverrides } from './SlideShowLayoutPaginationStyles';

const SliderSlideShowCleanWrap: FC<SlideShowProps> = ({
    slideAnimationProps: {
        slotsInFrame,
        cursor,
        slideAnimationDirection = 'horizontal',
        layout,
        paginationType,
    },
    slideProps,
    slidesData,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { setStyle, resetStyle } = useWrapperStyle(wrapperRef);
    const previousCursor = usePrevious(cursor);
    const { wrapDirection, resetWrapDirection } = useWrapDirection(
        cursor,
        slidesData
    );
    //this is the reset effect after  animation to last or first slide duplicate.
    useLayoutEffect(() => {
        if (wrapperRef.current && !wrapDirection) {
            resetStyle(['transitionDuration', 'left', 'right']);
        }
    }, [cursor, previousCursor, wrapperRef.current, wrapDirection]);

    const horizontal = slideAnimationDirection === 'horizontal';
    let adjustedSlidesData: SlideData[];
    let adjustedCursor: number;

    adjustedSlidesData = [
        slidesData[slidesData.length - 1], //last slide duplicate
        ...slidesData,
        slidesData[0], //first slide duplicate
    ];
    adjustedCursor = cursor + 1; //current index + duplicate slide we added above
    const numberOfSlides = slidesData.length;
    const lastSlideIndex =
        adjustedSlidesData.length - slotsInFrame - adjustedCursor;
    const { startPosition, endPosition } = calculatePositions({
        wrapDirection,
        numberOfSlides,
        currentSlideIndex: adjustedCursor,
        lastSlideIndex,
        slidesInFrame: slotsInFrame,
    });
    const preStartProp = horizontal ? 'left' : 'top';
    const postEndProp = horizontal ? 'right' : 'bottom';

    const indexesInFrame = new Array(slotsInFrame)
        .fill(null)
        .map((_, i) => adjustedCursor + i);

    const [stableSlides, setStableSlides] = useState<number[]>(indexesInFrame);
    const onTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = (
        e
    ) => {
        if (
            (e.propertyName === 'left' || e.propertyName === 'up') && //onTransitionEnd fired per property, left and right or up and down changed simultaniously, limiting to only one
            wrapDirection &&
            wrapperRef.current
        ) {
            setStyle({
                transitionDuration: '0ms',
                [wrapDirection === 'toLast' ? 'left' : 'right']: `-${
                    numberOfSlides * 100
                }%`,
                [wrapDirection === 'toLast' ? 'right' : 'left']: `-100%`,
            });
            //in order to trigger separate fiber update on next tick and reset the adjustments above.
            setTimeout(() => {
                resetWrapDirection();
            }, 0);
        }
        setStableSlides(indexesInFrame);
    };
    const slideShowLayoutPaginationStylesOverrides =
        getSlideShowLayoutPaginationStylesOverrides({
            paginationType,
            layout,
            slotsInFrame,
        });

    return (
        <Div
            styles={[
                slideShowCommonDefaultStyles.getContainer(slotsInFrame),
                {
                    [RuntimeMediaQuery.COMMON]: {
                        position: 'relative',
                        overflow: 'hidden',
                    },
                    mobile: {
                        width:
                            slotsInFrame < 3
                                ? `${100 * slotsInFrame}%`
                                : `${(250 / 3) * slotsInFrame}%`,
                    },
                },
                slideShowLayoutPaginationStylesOverrides?.container,
            ]}
        >
            <Div
                ref={wrapperRef}
                styles={[
                    slideShowCommonDefaultStyles.filmRole,
                    {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            flexDirection: horizontal ? 'row' : 'column',
                            [preStartProp]: startPosition,
                            [postEndProp]: endPosition,
                            transition: 'left, top, right, bottom',
                            transitionDuration: '1s',
                            transitionTimingFunction: 'ease-in-out',
                        },
                    },
                ]}
                onTransitionEnd={onTransitionEnd}
                data-auto='slider-filmRole'
            >
                {adjustedSlidesData.map((slideData, i) => (
                    <Div
                        styles={[
                            {
                                [RuntimeMediaQuery.COMMON]: {
                                    position: 'relative',
                                    flex: 1,
                                },
                            },
                        ]}
                        data-auto={`slideSlot ${i}${
                            adjustedCursor === i ? ' slideSlotActive' : ''
                        }`}
                        key={i}
                        className={`d-ext-mediaSlider-slidesContainer__slide${
                            adjustedCursor === i ? '--active' : ''
                        }`}
                    >
                        <Div
                            styles={[
                                slideShowCommonDefaultStyles.getSlideSlot(
                                    slotsInFrame
                                ),
                            ]}
                        >
                            <SlideContent
                                {...slideProps}
                                {...slideData}
                                contentAnimationMode={
                                    stableSlides.includes(i)
                                        ? 'anim-active'
                                        : 'anim-idle'
                                }
                                outOFViewPort={!stableSlides.includes(i)}
                            />
                        </Div>
                    </Div>
                ))}
            </Div>
        </Div>
    );
};

export default SliderSlideShowCleanWrap;
