import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { Div } from 'client/widget-components/basicComponents';
import React, { FC, useState } from 'react';
import SlideContent from '../SlideContent';
import { SlideData } from '../sliderCommonTypes';
import {
    slideShowCommonDefaultStyles,
    SlideShowProps,
} from './SlideShowCommon';
import { getSlideShowLayoutPaginationStylesOverrides } from './SlideShowLayoutPaginationStyles';

const SliderSlideShow: FC<SlideShowProps> = ({
    slideAnimationProps: {
        slotsInFrame,
        cursor,
        slideAnimationDirection = 'horizontal',
        layout,
        paginationType,
    },
    slideProps,
    slidesData,
}) => {
    const horizontal = slideAnimationDirection === 'horizontal';

    let adjustedSlidesData: SlideData[];
    let adjustedCursor: number;
    let preUnit: number;

    adjustedSlidesData = [...slidesData];
    for (let i = 0; i < slotsInFrame - 1; i++) {
        adjustedSlidesData.push(slidesData[i % slidesData.length]);
    }
    adjustedCursor = cursor;
    preUnit = cursor;
    const totalUnits = adjustedSlidesData.length;

    const postUnits = totalUnits - slotsInFrame - preUnit;

    const preStartVal = `-${(preUnit * 100) / slotsInFrame}%`;
    const preStartProp = horizontal ? 'left' : 'top';
    const postEndVal = `-${(postUnits * 100) / slotsInFrame}%`;
    const postEndProp = horizontal ? 'right' : 'bottom';

    const indexesInFrame = new Array(slotsInFrame)
        .fill(null)
        .map((_, i) => preUnit + i);

    const [stableSlides, setStableSlides] = useState<number[]>(indexesInFrame);
    const slideShowLayoutPaginationStylesOverrides =
        getSlideShowLayoutPaginationStylesOverrides({
            paginationType,
            layout,
            slotsInFrame,
        });
    return (
        <Div
            styles={[
                slideShowCommonDefaultStyles.getContainer(slotsInFrame),
                {
                    [RuntimeMediaQuery.COMMON]: {
                        position: 'relative',
                        overflow: 'hidden',
                    },
                    [RuntimeMediaQuery.MOBILE]: {
                        width:
                            slotsInFrame < 3
                                ? `${100 * slotsInFrame}%`
                                : `${(250 / 3) * slotsInFrame}%`,
                    },
                },
                slideShowLayoutPaginationStylesOverrides?.container,
            ]}
        >
            <Div
                styles={[
                    slideShowCommonDefaultStyles.filmRole,
                    {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            flexDirection: horizontal ? 'row' : 'column',
                            [preStartProp]: preStartVal,
                            [postEndProp]: postEndVal,
                            transition: 'left, top, right, bottom',
                            transitionDuration: '1s',
                            transitionTimingFunction: 'ease-in-out',
                        },
                    },
                ]}
                onTransitionEnd={() => setStableSlides(indexesInFrame)}
                data-auto='slider-filmRole'
            >
                {adjustedSlidesData.map((slideData, i) => (
                    <Div
                        styles={[
                            {
                                [RuntimeMediaQuery.COMMON]: {
                                    position: 'relative',
                                    flex: 1,
                                },
                            },
                        ]}
                        data-auto={`slideSlot ${i}${
                            adjustedCursor === i ? ' slideSlotActive' : ''
                        }`}
                        key={i}
                        className={`d-ext-mediaSlider-slidesContainer__slide${
                            adjustedCursor === i ? '--active' : ''
                        }`}
                    >
                        <Div
                            styles={[
                                slideShowCommonDefaultStyles.getSlideSlot(
                                    slotsInFrame
                                ),
                            ]}
                        >
                            <SlideContent
                                {...slideProps}
                                {...slideData}
                                contentAnimationMode={
                                    stableSlides.includes(i)
                                        ? 'anim-active'
                                        : 'anim-idle'
                                }
                                outOFViewPort={!stableSlides.includes(i)}
                            />
                        </Div>
                    </Div>
                ))}
            </Div>
        </Div>
    );
};

export default SliderSlideShow;
