import React, { CSSProperties } from 'react';
import withLink, { LinkProps } from '../../hocs/withLink';
import {
    DeprecatedResponsiveStyles,
    getBreakpointsStyle,
    SPECIFICITY_WRAP,
} from '../../responsive-styles/deprecated/styled-components-utils';
import styled from 'styled-components';
import DeprecatedText from 'client/widget-components/basicComponents/DeprecatedText';

type DirectionCssVal = CSSProperties['direction'];

export interface BreadcrumbLinkData {
    title: string;
    linkProps?: LinkProps;
}

export enum SeparatorType {
    arrow = 'arrow',
    divider = 'divider',
    double_arrow = 'double_arrow',
    slash = 'slash',
    horizontal = 'horizontal',
}

export interface StyledProps {
    styles?: DeprecatedResponsiveStyles;
}

interface StyledSeparatorProps extends StyledProps {
    direction: DirectionCssVal;
}

export interface BreadcrumbsProps {
    styles?: DeprecatedResponsiveStyles;
    separatorStyles?: DeprecatedResponsiveStyles;
    linksStyles?: DeprecatedResponsiveStyles;
    linksHoverStyles?: DeprecatedResponsiveStyles;
    unlinkableItemsStyles?: DeprecatedResponsiveStyles;
    currentItemStyles?: DeprecatedResponsiveStyles;
    links: BreadcrumbLinkData[];
    separatorType?: SeparatorType;
    ariaLabel?: string;
    showEmptyMessage?: boolean;
    emptyMessage?: string;
}

const defaultProps = {
    links: [],
    ariaLabel: 'Breadcrumbs',
    emptyMessage: 'No breadcrumbs',
};

const StyledBreadcrumbs = styled.nav<BreadcrumbsProps>`
    ${SPECIFICITY_WRAP} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        ${(props: BreadcrumbsProps) => getBreakpointsStyle(props.styles)}
    }
`;

const StyledSeparator = styled.span<StyledSeparatorProps>`
    ${SPECIFICITY_WRAP} {
        ${(props: StyledSeparatorProps) => getBreakpointsStyle(props?.styles)}
        transform: ${(props: StyledSeparatorProps) =>
            props?.direction === 'rtl' ? 'rotate(180deg)' : 'rotate(0)'};
        display: flex;
        width: 16px;
        svg {
            width: 100%;
            height: 100%;
            fill: currentColor;
        }
    }
`;

const getSvg = (type?: SeparatorType) => {
    switch (type) {
        case SeparatorType.arrow: {
            return (
                <svg
                    data-auto='arrow'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.46967 3.46967C5.76256 3.17678 6.23744 3.17678 6.53033 3.46967L10.5303 7.46967C10.8232 7.76256 10.8232 8.23744 10.5303 8.53033L6.53033 12.5303C6.23744 12.8232 5.76256 12.8232 5.46967 12.5303C5.17678 12.2374 5.17678 11.7626 5.46967 11.4697L8.93934 8L5.46967 4.53033C5.17678 4.23744 5.17678 3.76256 5.46967 3.46967Z'
                        fill='currentColor'
                    />
                </svg>
            );
        }
        case SeparatorType.double_arrow: {
            return (
                <svg
                    data-auto='double_arrow'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M3.21967 3.46967C3.51256 3.17678 3.98744 3.17678 4.28033 3.46967L8.28033 7.46967C8.57322 7.76256 8.57322 8.23744 8.28033 8.53033L4.28033 12.5303C3.98744 12.8232 3.51256 12.8232 3.21967 12.5303C2.92678 12.2374 2.92678 11.7626 3.21967 11.4697L6.68934 8L3.21967 4.53033C2.92678 4.23744 2.92678 3.76256 3.21967 3.46967Z'
                        fill='currentColor'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M7.71967 3.46967C8.01256 3.17678 8.48744 3.17678 8.78033 3.46967L12.7803 7.46967C13.0732 7.76256 13.0732 8.23744 12.7803 8.53033L8.78033 12.5303C8.48744 12.8232 8.01256 12.8232 7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697L11.1893 8L7.71967 4.53033C7.42678 4.23744 7.42678 3.76256 7.71967 3.46967Z'
                        fill='currentColor'
                    />
                </svg>
            );
        }
        case SeparatorType.divider: {
            return (
                <svg
                    data-auto='divider'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M8 2.58333C8.41421 2.58333 8.75 2.91912 8.75 3.33333V12.6667C8.75 13.0809 8.41421 13.4167 8 13.4167C7.58579 13.4167 7.25 13.0809 7.25 12.6667V3.33333C7.25 2.91912 7.58579 2.58333 8 2.58333Z'
                        fill='currentColor'
                    />
                </svg>
            );
        }

        case SeparatorType.slash: {
            return (
                <svg
                    data-auto='slash'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6.59807 13.2321C6.19797 13.1249 5.96053 12.7136 6.06774 12.3135L8.48338 3.29821C8.59059 2.89811 9.00184 2.66068 9.40194 2.76788C9.80204 2.87509 10.0395 3.28634 9.93227 3.68644L7.51662 12.7017C7.40942 13.1018 6.99817 13.3393 6.59807 13.2321Z'
                        fill='currentColor'
                    />
                </svg>
            );
        }
        case SeparatorType.horizontal: {
            return (
                <svg
                    data-auto='horizontal'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M13.4167 7.99998C13.4167 8.41419 13.0809 8.74998 12.6667 8.74998L3.33333 8.74998C2.91912 8.74998 2.58333 8.41419 2.58333 7.99998C2.58333 7.58576 2.91912 7.24998 3.33333 7.24998L12.6667 7.24998C13.0809 7.24998 13.4167 7.58576 13.4167 7.99998Z'
                        fill='currentColor'
                    />
                </svg>
            );
        }
    }
};

const renderSeparator = (
    styles: DeprecatedResponsiveStyles | undefined,
    dir: DirectionCssVal,
    type?: SeparatorType
) => {
    return type ? (
        <StyledSeparator
            styles={styles}
            direction={dir}
            className='bc-separator'
            aria-hidden='true'
        >
            {getSvg(type)}
        </StyledSeparator>
    ) : null;
};

const renderEmptyState = (
    showEmptyMessage?: boolean,
    emptyMessage?: string
) => {
    return showEmptyMessage ? (
        <span style={{ color: '#C1C9CC', padding: '0 4px' }}>
            {emptyMessage}
        </span>
    ) : null;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    links,
    styles,
    linksStyles,
    linksHoverStyles,
    separatorStyles,
    unlinkableItemsStyles,
    currentItemStyles,
    separatorType,
    ariaLabel,
    showEmptyMessage,
    emptyMessage,
}) => {
    return (
        <StyledBreadcrumbs
            links={links}
            styles={styles}
            className='bc-root dmBlockElement'
            aria-label={ariaLabel}
        >
            {links.length
                ? links.map((link, index) => {
                      const LinkText = () => (
                          <DeprecatedText>{link.title}</DeprecatedText>
                      );
                      const Link = withLink(LinkText, {
                          ...link.linkProps,
                          styles: linksStyles,
                          hoverStyles: linksHoverStyles,
                          className: 'bc-item',
                      });
                      const currentItem = link?.linkProps?.currentPage ? (
                          <DeprecatedText
                              styles={currentItemStyles}
                              domAttrs={{
                                  className: 'bc-current-item',
                              }}
                              data-auto='bc-current-item'
                          >
                              {link.title}
                          </DeprecatedText>
                      ) : null;
                      const unlinkableItem =
                          link?.linkProps?.href == '#' ? (
                              <DeprecatedText
                                  styles={unlinkableItemsStyles}
                                  domAttrs={{ className: 'bc-unlinkable-item' }}
                                  data-auto='bc-unlinkable-item'
                              >
                                  {link.title}
                              </DeprecatedText>
                          ) : null;
                      const linkableItem =
                          link?.linkProps?.href != '#' &&
                          !link?.linkProps?.currentPage ? (
                              <Link />
                          ) : null;

                      return (
                          <React.Fragment key={`link-${index}`}>
                              {linkableItem}
                              {unlinkableItem}
                              {currentItem}
                              {index < links.length - 1
                                  ? renderSeparator(
                                        {
                                            ...separatorStyles,
                                        },
                                        styles?.default?.direction || 'ltr',
                                        separatorType
                                    )
                                  : null}
                          </React.Fragment>
                      );
                  })
                : renderEmptyState(showEmptyMessage, emptyMessage)}
        </StyledBreadcrumbs>
    );
};

Breadcrumbs.defaultProps = defaultProps;

export default Breadcrumbs;
